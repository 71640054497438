(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/team-list/assets/javascripts/team-skeleton.js') >= 0) return;  svs.modules.push('/components/marketplace/team-list/assets/javascripts/team-skeleton.js');
"use strict";


const {
  Skeleton
} = svs.components.lbUi;
const {
  GameSkeleton
} = svs.components.marketplace.gameList;
const TeamSkeleton = () => React.createElement("div", {
  className: "margin-half",
  style: {
    display: 'inline-flex',
    flexDirection: 'column'
  }
}, React.createElement(Skeleton, {
  height: 1,
  numLines: 1,
  style: {
    width: '350px'
  }
}), React.createElement("div", {
  className: "margin-half-top margin-half-bottom"
}, React.createElement(Skeleton, {
  height: 4,
  numLines: 1,
  style: {
    width: '350px'
  }
})), React.createElement(GameSkeleton, null));
setGlobal('svs.components.marketplace.teamList.TeamSkeleton', TeamSkeleton);

 })(window);