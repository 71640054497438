(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/team-list/assets/javascripts/team-list-item.js') >= 0) return;  svs.modules.push('/components/marketplace/team-list/assets/javascripts/team-list-item.js');

'use strict';

const {
  Link
} = ReactRouterDOM;
const {
  GroupHeader
} = svs.components.marketplace.groupHeader;
const {
  roles
} = svs.components.marketplaceData.auth;
const {
  ReactPaper: Paper
} = svs.ui;
const {
  useEffect,
  useRef
} = React;
const {
  useHistory
} = ReactRouterDOM;
const TeamListItem = _ref => {
  let {
    team,
    children,
    color,
    linkTo,
    unreadMessages,
    className,
    onClick
  } = _ref;
  const el = useRef();
  const history = useHistory();
  useEffect(() => {
    if (el.current) {
      let hasScrolled = false;
      svs.components.Storage.browser.get('lastClickedTeam', 'marketplace', data => {
        if (data !== null && data !== void 0 && data.groupId && data.groupId === team.id) {
          hasScrolled = true;
          el.current.scrollIntoView({
            behavior: 'instant',
            block: 'center'
          });
          svs.components.Storage.browser.set('lastClickedTeam', 'marketplace', null);
        }
      });

      if (!hasScrolled && history.location.state && history.location.state.scrollToGroup) {
        el.current.scrollIntoView({
          behavior: 'instant',
          block: 'center'
        });
        history.location.state.scrollToGroup = null;
      }
    }
  }, []);
  const rootClassName = ['team-container'];
  if (className) {
    rootClassName.push(className);
  }

  const LinkWrapper = _ref2 => {
    let {
      children
    } = _ref2;
    return linkTo || onClick ? React.createElement(Link, {
      className: "qa-redirect-team-header",
      onClick: onClick,
      to: linkTo
    }, children) : children;
  };
  return React.createElement(Paper, {
    branding: "sport",
    className: rootClassName.join(' ')
  }, React.createElement("div", {
    className: "header-link-holder",
    ref: el
  }, React.createElement(LinkWrapper, null, React.createElement(GroupHeader, {
    className: "team-list-heading",
    color: color,
    groupId: team.id || team.groupId,
    isCaptain: team.role === roles.ROLES.CAPTAIN,
    isInverted: false,
    membersCount: team.membersCount,
    myRole: team.role,
    teamName: team.name,
    unreadMessages: unreadMessages
  }))), children);
};
TeamListItem.defaultProps = {
  linkTo: ''
};
setGlobal('svs.components.marketplace.teamList.TeamListItem', TeamListItem);

 })(window);