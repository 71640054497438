(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/team-list/assets/javascripts/team-list.js') >= 0) return;  svs.modules.push('/components/marketplace/team-list/assets/javascripts/team-list.js');

'use strict';

const {
  useSelector
} = ReactRedux;
const {
  GroupList
} = svs.components.marketplace.groupList;
const {
  TeamListItem
} = svs.components.marketplace.teamList;
const {
  helpers
} = svs.components.marketplace;
const {
  getColor
} = svs.components.marketplace.colorHelper;
const {
  useHistory
} = ReactRouterDOM;
const {
  getTeams,
  getRetailers
} = svs.components.marketplaceData.selectors.teams;
const {
  selectChatRooms
} = svs.components.marketplaceData.selectors.chat;
const TeamList = _ref => {
  let {
    isBrowseGames,
    isRetail,
    children,
    renderOnEmpty,
    isHeaderInverted,
    teamClassName,
    teamLinkSearch,
    maxGamesToDisplay,
    offsetForLastClicked,
    shouldUseLastClicked
  } = _ref;
  const history = useHistory();
  const teams = useSelector(state => getTeams(state));
  const retailers = useSelector(state => getRetailers(state, isBrowseGames) || []);
  const chatRooms = useSelector(state => selectChatRooms(state));
  return React.createElement(GroupList, {
    listData: isRetail ? retailers : teams,
    renderItem: (team, key) => React.createElement(TeamListItem, {
      className: teamClassName,
      color: getColor(team.id),
      history: history,
      isHeaderInverted: isHeaderInverted,
      isRetail: isRetail,
      key: team.id || key,
      linkTo: {
        pathname: isRetail ? helpers.getRetailUrl(team.id, team.name) : helpers.getTeamUrl(team.id, team.name),
        search: teamLinkSearch
      },
      maxGamesToDisplay: maxGamesToDisplay,
      onClick: () => {
        if (shouldUseLastClicked) {
          svs.components.Storage.browser.set('lastClickedTeam', 'marketplace', {
            groupId: team.id,
            offset: offsetForLastClicked
          });
        }
      },
      team: team,
      unreadMessages: (chatRooms["marketplace_".concat(team.id)] || {}).unread
    }, children(team)),
    renderOnEmpty: renderOnEmpty
  });
};
TeamList.defaultProps = {
  children: () => {},
  isHeaderInverted: false,
  isRetail: false
};
setGlobal('svs.components.marketplace.teamList.TeamList', TeamList);

 })(window);